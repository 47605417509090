import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

import { CSVLink } from 'react-csv';
import moment from 'moment';
import { isAuthError } from '../utils';

import {
  InputAdornment,
  CircularProgress,
  LinearProgress,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  createFilterOptions,
} from '@mui/material';

import { Error } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/SaveAlt';

const LEGACY_SAR_QUERY = gql`
  query LegacySARs(
    $emailAddress: String
    $onOrBefore: String
    $onOrAfter: String
    $building: String
    $protocolNumber: String
  ) {
    legacySARs(
      emailAddress: $emailAddress
      onOrBefore: $onOrBefore
      onOrAfter: $onOrAfter
      building: $building
      protocolNumber: $protocolNumber
    ) {
      id
      dateSubmitted
      protocolNumber
      vetCardNo
      building
      room
      rack
      numSick
      numTotal
      observations
      description
      recipients
      reportSubmitter
      species
    }
  }
`;

const loadingProps = {
  startAdornment: (
    <InputAdornment position="start">
      <CircularProgress />
    </InputAdornment>
  ),
};

const errorProps = {
  startAdornment: (
    <InputAdornment position="start">
      <Error />
    </InputAdornment>
  ),
};

const filterOptions = createFilterOptions({
  limit: 5,
});

const LegacySARTable = ({ protocolNumber, building, onOrAfter, onOrBefore, emailAddress, onFilter }) => {
  const {
    loading,
    error,
    data = { legacySARs: [] },
    refetch,
  } = useQuery(LEGACY_SAR_QUERY, {
    variables: {
      protocolNumber,
      building,
      onOrAfter,
      onOrBefore,
      emailAddress,
    },
  });
  let rows = [...data.legacySARs]
    .sort((x, y) => moment(y.dateSubmitted).unix() - moment(x.dateSubmitted).unix())
    .map((x) => {
      const y = { ...x };
      delete y.__typename;
      return y;
    });

  let filename = 'SAR';
  if (protocolNumber) filename += `_${protocolNumber}`;
  if (building) filename += `_${building}`;
  if (onOrAfter) filename += `_from_${moment(onOrAfter).format('MM-DD-YYYY')}`;
  if (onOrBefore) filename += `_through_${moment(onOrBefore).format('MM-DD-YYYY')}`;
  filename += '.csv';

  if ((loading || !data) && error) {
    if (isAuthError(error)) {
      window.location.href = '/login';
    }
    console.error('Error fetching users in LegacySARTable.');
    console.error(error);
  }

  if (error) {
    console.log('error: ', error);

    return <Error />;
  }

  const renderDateLabels = () => {
    if (onOrAfter && onOrBefore)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;From {moment(onOrAfter).format('YYYY-MM-DD')}{' '}
          through {moment(onOrBefore).format('YYY-MM-DD')}
        </Typography>
      );
    else if (onOrAfter)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;On or after{' '}
          {moment(onOrAfter).format('YYYY-MM-DD')}
        </Typography>
      );
    else if (onOrBefore)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;On or before{' '}
          {moment(onOrBefore).format('YYYY-MM-DD')}
        </Typography>
      );
    return null;
  };

  return (
    <>
      {loading || !rows ? (
        <LinearProgress />
      ) : (
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            {protocolNumber && (
              <Typography style={{ textAlign: 'left' }}>
                <span style={{ fontWeight: 'bold' }}>Protocol:</span>&nbsp;{protocolNumber}
              </Typography>
            )}
            {building && (
              <Typography style={{ textAlign: 'left' }}>
                <span style={{ fontWeight: 'bold' }}>Building:</span>&nbsp;{building}
              </Typography>
            )}
            {renderDateLabels()}
            <div style={{ marginBottom: 10 }} />
          </Grid>
          <Grid item xs={4}>
            {onFilter && (
              <IconButton aria-label="update-query" onClick={onFilter} style={{ float: 'right' }}>
                <SearchIcon fontSize="large" />
              </IconButton>
            )}
            <IconButton aria-label="refresh-results" onClick={() => refetch()} style={{ float: 'right' }}>
              <RefreshIcon fontSize="large" />
            </IconButton>
            <CSVLink data={rows} target="_blank" filename={filename}>
              <IconButton aria-label="download-csv" onClick={console.log} style={{ float: 'right' }}>
                <DownloadIcon fontSize="large" />
              </IconButton>
            </CSVLink>
          </Grid>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date Submitted</TableCell>
                  <TableCell align="right">Protocol Number</TableCell>
                  <TableCell align="right">Building</TableCell>
                  <TableCell align="right">Room</TableCell>
                  <TableCell align="right">Rack</TableCell>
                  <TableCell align="right"># Sick</TableCell>
                  <TableCell align="right"># Total</TableCell>
                  <TableCell align="right">Observations</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Species</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(
                  ({
                    id,
                    dateSubmitted,
                    protocolNumber,
                    building,
                    room,
                    rack,
                    numSick,
                    numTotal,
                    observations,
                    description,
                    species,
                  }) => (
                    <TableRow key={id}>
                      <TableCell component="th" scope="row">
                        {moment(dateSubmitted).format('YYYY-MM-DD hh:mm a')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {protocolNumber}
                      </TableCell>
                      <TableCell align="right">{building}</TableCell>
                      <TableCell align="right">{room}</TableCell>
                      <TableCell align="right">{rack}</TableCell>
                      <TableCell align="right">{numSick}</TableCell>
                      <TableCell align="right">{numTotal}</TableCell>
                      <TableCell align="right">{observations.join()}</TableCell>
                      <TableCell align="right" style={{ maxWidth: 500 }}>
                        {description}
                      </TableCell>
                      <TableCell align="right">{species}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};

export default LegacySARTable;
