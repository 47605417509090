import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Paper,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  IconButton,
} from '@mui/material';

import { Error, KeyboardArrowLeft } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { isAuthError, capitalize } from '../utils';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { PageWrapper } from '../components/PageWrapper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const SPECIAL_CONDITIONS_QUERY = gql`
  query ProtocolSpecialConditions($protocolNumber: String!) {
    protocolSpecialConditions(protocolNumber: $protocolNumber) {
      species
      conditionId
      description
      value
    }
  }
`;
const relevantConditionIds = [
  'nsh10',
  'nsh20',
  'nsh30',
  'nsh40',
  'nsh50',
  'nsh80',
  'nsh91',
  'nsh92',
  'nsh95',
  'nsh96',
  'nsh99',
  'nsh9998',
  'surgery',
  'nsp20',
  'nsp23',
  'nsp25',
  'nsp30',
  'nsp40',
  'nsp50',
  'nsp70',
];

const getObj = (data) => {
  if (data && Array.isArray(data.protocolSpecialConditions)) {
    let obj = {};
    data.protocolSpecialConditions
      .filter((x) => {
        if (
          x.conditionId === 'nsh50' &&
          !(x.species.toLowerCase().includes('dog') || x.species.toLowerCase().includes('canine'))
        ) {
          return false;
        }
        return relevantConditionIds.includes(x.conditionId);
      })
      .forEach((x) => {
        if (obj[x.species]) {
          obj[x.species][x.conditionId] = { ...x };
        } else {
          const speciesObj = {};
          speciesObj[x.conditionId] = { ...x };
          obj[x.species] = speciesObj;
        }
      });
    return { obj, keys: Object.keys(obj) };
  }
  return { obj: {}, keys: [] };
};

const SpecialConditionsTable = ({ protocolNumber, PIName, onClose }) => {
  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { loading, error, data, refetch } = useQuery(SPECIAL_CONDITIONS_QUERY, {
    variables: {
      protocolNumber,
    },
  });
  if ((loading || !data) && error) {
    if (isAuthError(error)) {
      window.location.href = '/login';
    }
    console.error('Error fetching conditions in SpecialConditionsTable.');
    console.error(error);
  }

  if (error) {
    console.log('error: ', error);
    return <Error />;
  }

  let { obj, keys } = getObj(data);
  console.log('obj: ', obj);

  return (
    <PageWrapper title="Special Considerations">
      {getHeader({ onClose, protocolNumber, PIName })}

      {/* Loading */}
      {loading && <LinearProgress />}

      {/* No results */}
      {keys.length === 0 && !loading && (
        <>
          <Typography variant="body2">No Special Consideration Information Available for {protocolNumber}</Typography>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <IconButton aria-label="edit item" onClick={onClose}>
                <KeyboardArrowLeft />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}

      {/* Results */}
      {keys.length > 0 && (
        <>
          <Paper position="static" style={{ backgroundColor: '#646569', color: '#fff' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs"
              variant="scrollable"
              scrollButtons="on"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#c5050c',
                  height: 5,
                },
              }}
              sx={{ paddingInline: '1em' }}
            >
              {keys.map((species, i) => {
                return (
                  <Tab
                    {...a11yProps(i.toString())}
                    style={{ color: i.toString() === value ? '#fff' : '#f7f7f7', fontWeight: 600 }}
                    key={species}
                    value={i.toString()}
                    label={species}
                    wrapped
                  />
                );
              })}
            </Tabs>
          </Paper>
          {keys.map((species, i) => {
            return (
              <TabPanel
                value={value}
                index={i.toString()}
                //  style={{ display: value === i ? undefined : 'none' }}
              >
                <List dense>
                  {relevantConditionIds.map((conditionId, j) => {
                    const condition = obj[species][conditionId];
                    if (!condition) return null;
                    const { description, value } = condition;
                    return (
                      <span>
                        <ListItem key={conditionId + species}>
                          <ListItemText primary={capitalize(description)} />
                          <ListItemSecondaryAction>
                            {value ? (
                              <CheckIcon style={{ marginTop: 4, color: 'green' }} />
                            ) : (
                              <CloseIcon style={{ marginTop: 4, color: '#c5050c' }} />
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />
                      </span>
                    );
                  })}
                </List>
              </TabPanel>
            );
          })}
        </>
      )}
    </PageWrapper>
  );
};

const getHeader = ({ onClose, protocolNumber, PIName }) => (
  <>
    <Grid container justifyContent="flex-start">
      <Grid item>
        <IconButton aria-label="edit item" onClick={onClose}>
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>
    </Grid>

    <Grid container justifyContent="space-between">
      <Grid item xs={8}>
        {protocolNumber && (
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Protocol:</span>&nbsp;{protocolNumber}
          </Typography>
        )}
        {PIName && (
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>PI:</span>&nbsp;{PIName}
          </Typography>
        )}
        <div style={{ marginBottom: 10 }} />
      </Grid>
    </Grid>
  </>
);

export default SpecialConditionsTable;
