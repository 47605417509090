import React, { useState } from 'react';

import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Table,
  LinearProgress,
  TextField,
  Grid,
  Snackbar,
  Alert,
  AlertTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { PageWrapper } from '../components/PageWrapper';
import InventoryCart from './InventoryCart';
import moment from 'moment';
import { buildingCodeToName } from '../utils';
import { KeyboardArrowLeft } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'number', numeric: true, disablePadding: false, label: 'Order No.' },
  { id: 'dateSubmitted', numeric: false, disablePadding: false, label: 'Date Submitted' },
  { id: 'submittedByName', numeric: false, disablePadding: false, label: 'Submitted By' },
  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { filterInput, onFilterInputChange } = props;

  return (
    <Toolbar>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h5" id="tableTitle" component="div">
            Past Orders
          </Typography>
        </Grid>
        <Grid item xs={6} sm={5} md={4}>
          <div style={{ minWidth: 300 }}>
            <TextField
              value={filterInput}
              onChange={(e) => onFilterInputChange(e.target.value)}
              placeholder="Filter"
              size="small"
            />
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const GET_INVENTORY_ORDERS = gql`
  query InventoryOrders($terms: String) {
    inventoryCarts(terms: $terms) {
      user {
        PVI
        firstName
        lastName
      }
      completedBy {
        PVI
        firstName
        lastName
      }
      dateSubmitted
      dateCompleted
      status
      _id
      number
      building
    }
  }
`;

const GET_INVENTORY_CART = gql`
  query {
    userInventoryCart {
      items {
        quantity
        item {
          _id
          name
          unit
          vendor
          code
          description
        }
      }
    }
  }
`;

// const headCells = [
//     { id: 'dateSubmitted', numeric: false, disablePadding: true, label: 'Date Submitted' },
//     { id: 'submittedByName', numeric: false, disablePadding: false, label: 'Submitted By' },
//     { id: 'dateCompleted', numeric: false, disablePadding: false, label: 'Date Completed' },
//     { id: 'completedByName', numeric: false, disablePadding: false, label: 'Completed By' },
//     { id: 'numItems', numeric: false, disablePadding: false, label: 'No. Items' },
//     { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
// ];

const processRows = ({ data, filterInput }) => {
  if (!data || !data.inventoryCarts) {
    return [];
  }
  const { inventoryCarts } = data;
  if (!Array.isArray(inventoryCarts)) {
    console.error('Inventory Carts on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'dateSubmitted', 'dateCompleted', 'completedByName', 'submittedByName', 'number', 'building'];
  let res = inventoryCarts.map((x) => {
    const obj = {
      ...x,
    };
    console.log('x: ', x);

    if (!obj._id) {
      throw new Error(`Data object improperly formatted Obj: ${JSON.stringify(x)}`);
    }
    const { user, completedBy } = obj;
    obj.submittedByName = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.PVI;
    if (completedBy) {
      obj.completedByName =
        completedBy.firstName && completedBy.lastName
          ? `${completedBy.firstName} ${completedBy.lastName}`
          : completedBy.PVI;
    }
    if (obj.dateSubmitted) {
      obj.dateSubmitted = moment(obj.dateSubmitted).format('M/D/YYYY hh:mm a');
    }
    if (obj.dateCompleted) {
      obj.dateCompleted = moment(obj.dateCompleted).format('M/D/YYYY hh:mm a');
    }
    obj.status = obj.status.charAt(0).toUpperCase() + obj.status.slice(1);
    obj.building = buildingCodeToName(obj.building) || obj.building;
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    return res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        }
      }
      if (x.number && x.number.toString().includes(filterInput)) {
        return true;
      }
      return false;
    });
  }
  return res;
};

export default function InventoryItemsTable({ user }) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('number');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterInput, setFilterInput] = React.useState('');
  const [cartOpen, setCartOpen] = React.useState(false);
  const [toast, setToast] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_INVENTORY_ORDERS, {
    variables: { terms: JSON.stringify({ dateSubmitted: { $exists: true } }) },
  });
  const { loading: cartLoading, error: cartError, data: cartData, refetch: refetchCart } = useQuery(GET_INVENTORY_CART);
  const rows = processRows({ data, filterInput });
  console.log('rows: ', rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getBadgeContent = () => {
    if (
      cartData &&
      cartData.userInventoryCart &&
      Array.isArray(cartData.userInventoryCart.items) &&
      cartData.userInventoryCart.items.length > 0
    ) {
      return cartData.userInventoryCart.items.length;
    }
    return null;
  };

  console.log('getBadgeContent(): ', getBadgeContent());

  if (cartOpen) {
    const cart = { ...cartData.userInventoryCart };
    if (!Array.isArray(cart.items)) {
      cart.items = [];
    }
    return (
      <InventoryCart
        user={user}
        cart={cart}
        refetch={refetchCart}
        onClose={() => {
          setCartOpen(false);
        }}
        onSubmit={() => {
          setCartOpen(false);
          setToast('Your order has been placed!');
        }}
      />
    );
  }

  if (loading) {
    return (
      <PageWrapper>
        <LinearProgress />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Inventory Orders">
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="edit item"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <EnhancedTableToolbar filterInput={filterInput} onFilterInputChange={setFilterInput} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {}
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row._id}
                        onClick={
                          row.number
                            ? () => {
                                window.location.href = `/inventory-orders/${row.number}`;
                              }
                            : null
                        }
                        style={row.number ? { cursor: 'pointer' } : null}
                      >
                        <TableCell component="th" id={labelId} scope="row" align="center">
                          {row.number}
                        </TableCell>
                        <TableCell align="left">{row.dateSubmitted}</TableCell>
                        <TableCell align="left">{row.submittedByName}</TableCell>
                        <TableCell align="left">{row.building}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={toast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
