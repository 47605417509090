import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  AlertTitle,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { PageWrapper } from '../components/PageWrapper';

const AddInventoryItemToCart = ({ user, onSubmit, onClose, inventoryItem }) => {
  const { _id, name, unit, code, vendor, description } = inventoryItem;
  const [quantity, setQuantity] = useState(1);
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const resetForm = () => {
    setQuantity(1);
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const ADD_INVENETORY_ITEM = gql`
    mutation name($item: InputInventoryItem!, $quantity: Int!) {
      addInventoryItemToCart(item: $item, quantity: $quantity) {
        _id
      }
    }
  `;

  const getVariables = () => {
    if (!name || !unit) {
      throw new Error('incomplete inventory item in AddInventoryItemToCart Component');
    }
    let item = { ...inventoryItem };
    delete item.__typename;
    return {
      item,
      quantity: typeof quantity === 'number' ? quantity : parseInt(quantity),
    };
  };
  const [addInventoryItemToCart] = useMutation(ADD_INVENETORY_ITEM, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'addInventoryItemToCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
        onSubmit();
        setSubmitting(false);
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    addInventoryItemToCart({ variables });
  };

  return submitting ? (
    <CircularProgress />
  ) : (
    <PageWrapper title={`Add ${name} to Your Cart`}>
      <span>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            // sleep(500);
            handleSubmit();
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={9}>
              <Grid container justifyContent="center" alignItems="center" gap=".5em">
                <Grid item>
                  <TextField
                    variant="outlined"
                    style={{ width: 150, margin: 10 }}
                    required
                    label="Quantity"
                    placeholder="Enter a number"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={quantity}
                    inputProps={{
                      min: 1,
                      max: 1000000000,
                    }}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ textAlign: 'left' }}>x&nbsp;{unit}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              {code && (
                <Typography style={{ textAlign: 'left' }}>
                  <span style={{ fontWeight: 'bold' }}>Code:</span>&nbsp;{code}
                </Typography>
              )}
              {vendor && (
                <Typography style={{ textAlign: 'left' }}>
                  <span style={{ fontWeight: 'bold' }}>Vendor:</span>&nbsp;{vendor}
                </Typography>
              )}
              {description && (
                <Typography style={{ textAlign: 'left' }}>
                  <span style={{ fontWeight: 'bold' }}>Description:</span>&nbsp;{description}
                </Typography>
              )}
            </Grid>

            <Grid item xs={9} style={{ marginTop: 16 }}>
              {onClose && (
                <Button
                  style={{ width: 125, marginRight: 50 }}
                  variant="contained"
                  // color="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
              <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={loading}>
                Add
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={toast}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleToastClose} variant="filled" severity="success">
            <AlertTitle>Success</AlertTitle>
            Item added to Cart!
          </Alert>
        </Snackbar>
        <Dialog open={errorDialog} onClose={handleToastClose}>
          <DialogTitle>{'Error'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Failed to add item to cart</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToastClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </PageWrapper>
  );
};

export default AddInventoryItemToCart;
