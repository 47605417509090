import React from 'react';
import { Container, Typography } from '@mui/material';

import { PageWrapper } from '../components/PageWrapper';

const NotAuthenticated = () => {
  return (
    <PageWrapper title="Not Authenticated">
      <Container>
        <Typography>
          Click <a href="/login">here</a> to sign in with your NetID.
        </Typography>
      </Container>
    </PageWrapper>
  );
};

export default NotAuthenticated;
