import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ProtocolSearch from './ProtocolSearch';
import SpecialConditionsTable from './SpecialConditionsTable';
import { PageWrapper } from '../components/PageWrapper';

const SpecialNotificationConditionLookup = ({ user }) => {
  const [protocol, setProtocol] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [key, setKey] = useState(false);

  return submitted ? (
    <SpecialConditionsTable
      protocolNumber={protocol.label.split(':')[0]}
      PIName={protocol.label.split(':')[1].replace(' ', '')}
      onClose={() => setSubmitted(false)}
    />
  ) : (
    <PageWrapper title="Special Considerations Search">
      <Typography variant="subtitle1" paddingBottom="1em">
        Select a Protocol to View Special Considerations
      </Typography>

      <form onSubmit={() => setSubmitted(true)}>
        <Grid container justifyContent="center" spacing={2} alignItems="center" paddingInline="20%">
          <Grid item xs={12} sm={6}>
            <ProtocolSearch
              name="protocol"
              fullWidth
              required
              key={key}
              onSelectProtocol={setProtocol}
              val={protocol ? protocol.protocolNumber : undefined}
              allowFreeFill
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={!protocol}>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageWrapper>
  );
};

export default SpecialNotificationConditionLookup;
