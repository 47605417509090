/**
 * This component is used by a monitor in CSC.
 */
import React, { useState, useEffect } from 'react';

import {
  Table,
  LinearProgress,
  TextField,
  Grid,
  Snackbar,
  Paper,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Toolbar,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import OverdueStatusIcon from '@mui/icons-material/PriorityHigh';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { PageWrapper } from '../components/PageWrapper';
import moment from 'moment';
import { buildingCodeToName, capitalize } from '../utils';

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const GET_FAST_REQUESTS = gql`
  query FastRequests($terms: String) {
    fastRequestsDisplay(terms: $terms) {
      _id
      protocolNumber
      PIName
      number
      building
      animalId
      fastStart
      permittedPVIs
      status
      terminal
      procedureDate
      fastEnd
    }
  }
`;

const formatStatus = (status) => {
  if (status === 'awaitingReturn') {
    return 'Awaiting Return';
  }
  if (status === 'returnOverdue') {
    return 'Return Overdue';
  }
  return capitalize(status);
};

// { id: 'number', numeric: true, disablePadding: false, label: 'Order No.' },
// { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
// { id: 'fastStartUnix', numeric: false, disablePadding: false, label: 'Date Submitted' },
// { id: 'fastEndUnix', numeric: false, disablePadding: false, label: 'Submitted By' },
// { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
const processRows = ({ data }) => {
  if (!data || !data.fastRequestsDisplay) {
    console.log('data: ', data);

    return [];
  }
  const { fastRequestsDisplay } = data;
  if (!Array.isArray(fastRequestsDisplay)) {
    console.error('Inventory Carts on provided data set is not an array');
    return [];
  }
  const keys = [
    '_id',
    'number',
    'PIName',
    'building',
    'fastStartUnix',
    'fastEndUnix',
    'terminal',
    'animalId',
    'status',
  ];
  let res = fastRequestsDisplay.map((x) => {
    const obj = {
      ...x,
    };

    if (!obj._id) {
      throw new Error(`Data object improperly formatted Obj: ${JSON.stringify(x)}`);
    }
    obj.status = formatStatus(obj.status);
    obj.building = buildingCodeToName(obj.building) || obj.building;
    obj.fastStartUnix = moment(obj.fastStart).unix();
    obj.fastEndUnix = obj.fastEnd ? moment(obj.fastEnd).unix() : moment(obj.procedureDate).unix();
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    console.log('obj: ', obj);

    return obj;
  });
  return res.filter((x) => {
    const tomorrowMoment = moment().tz('America/Chicago').add(1, 'day').hour(0).minute(0).second(0).millisecond(0);
    console.log('------------------------------------------------');
    console.log('x: ', x);

    // return true if overdue
    if (x.status === 'Overdue') {
      console.log('overdue');
      return true;
    }
    // return false if cancelled or returned already
    if (['Returned', 'Cancelled', 'Completed'].includes(x.status)) {
      console.log('cancelled, completed, or returned');
      return false;
    }
    // return true if fast start is today
    if (moment(x.fastStart).unix() < tomorrowMoment.unix()) {
      console.log('starts today');
      return true;
    }
    // return true if procedure date is today
    if (moment(x.procedureDate).unix() < tomorrowMoment.unix()) {
      console.log('procedure is today');
      return true;
    }
    // return true if return date is today
    if (!x.terminal && moment(x.fastEnd).unix() < tomorrowMoment.unix()) {
      console.log('return today');
      return true;
    }
    console.log('returning false');

    return false;
  });
};

const returnColor = '#488948';
const cancelColor = '#dadfe1';
const overdueColor = '#c5050b';
const inProgressColor = '#006a8a';

const getColor = (status) => {
  if (status === 'Returned' || status === 'Completed') {
    return returnColor;
  }
  if (status === 'Cancelled') {
    return cancelColor;
  }
  if (status === 'Overdue' || status === 'Return Overdue') {
    return overdueColor;
  }
  return inProgressColor;
};

export default function FastRequestsTable({ user: { PVI } }) {
  const { loading, error, data, refetch } = useQuery(GET_FAST_REQUESTS, {
    variables: { terms: JSON.stringify({}) },
  });

  useInterval(refetch, 5000);

  const rows = processRows({ data });

  console.log('rows: ', rows);

  if (loading) {
    return (
      <PageWrapper>
        <LinearProgress />
      </PageWrapper>
    );
  }

  const dayRows = processRows({ data });
  const toFast = [];
  const toReturn = [];
  dayRows.forEach((x) => {
    if (['Submitted', 'Overdue'].includes(x.status)) {
      toFast.push(x);
    } else {
      toReturn.push(x);
    }
  });
  return (
    <Grid container justifyContent={'space-around'}>
      <Grid item xs={12} lg={5}>
        <Paper style={{ minHeight: 900, margin: '0px auto 20px auto', padding: 16 }}>
          <TableContainer>
            <Typography
              variant="h5"
              style={{ marginTop: 50, marginBottom: 16, textDecoration: 'underline', textAlign: 'center' }}
            >
              Awaiting Fasting
            </Typography>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                headCells={[
                  { id: 'PIName', numeric: true, disablePadding: false, label: 'PI' },
                  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
                  { id: 'animalId', numeric: false, disablePadding: false, label: 'Animal ID' },
                  { id: 'fastStartUnix', numeric: false, disablePadding: false, label: 'Fast Start' },
                  { id: 'statusIndicator', numeric: false, disablePadding: false, label: '' },
                ]}
                rowCount={toFast.length}
              />
              <TableBody>
                {stableSort(toFast, getComparator('asc', 'fastStartUnix')).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row._id} style={row.number ? { cursor: 'pointer' } : null}>
                      <TableCell align="left">{row.PIName}</TableCell>
                      <TableCell align="left">{row.building}</TableCell>
                      <TableCell align="left">{row.animalId}</TableCell>
                      <TableCell align="left">{moment.unix(row.fastStartUnix).format('MM/DD/YYYY h:mm a')}</TableCell>
                      <TableCell align="left">
                        {row.status.includes('Overdue') && (
                          <OverdueStatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={5}>
        <Paper style={{ minHeight: 900, margin: '0px auto 20px auto', padding: 16 }}>
          <TableContainer>
            <Typography
              variant="h5"
              style={{
                marginTop: 50,
                marginBottom: 16,
                textDecoration: 'underline',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Awaiting Procedure or Return
            </Typography>

            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                headCells={[
                  { id: 'PIName', numeric: true, disablePadding: false, label: 'PI' },
                  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
                  { id: 'animalId', numeric: false, disablePadding: false, label: 'Animal ID' },
                  { id: 'fastEndUnix', numeric: false, disablePadding: false, label: 'Fast End' },
                  { id: 'statusIndicator', numeric: false, disablePadding: false, label: '' },
                ]}
                rowCount={toReturn.length}
              />
              <TableBody>
                {stableSort(toReturn, getComparator('asc', 'fastEndUnix')).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row._id} style={row.number ? { cursor: 'pointer' } : null}>
                      <TableCell align="left">{row.PIName}</TableCell>
                      <TableCell align="left">{row.building}</TableCell>
                      <TableCell align="left">{row.animalId}</TableCell>
                      <TableCell align="left">
                        {row.terminal ? 'Terminal Procedure' : moment.unix(row.fastEndUnix).format('MM/DD/YYYY h:mm a')}
                      </TableCell>
                      <TableCell align="left">
                        {row.status.includes('Overdue') && (
                          <OverdueStatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
