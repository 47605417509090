import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

/**
 * Creates a standard container to use on any page.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children to render in the container.
 * @param {string} props.title - The title of the container.
 * @param {boolean} props.xl - If the container should be the full width of the screen.
 * @returns {JSX.Element} The rendered component.
 */

export const PageWrapper = ({ children, xl, title }) => {
  return (
    <Box margin="auto" width={xl ? '99%' : 'min(90ch, 99%)'}>
      <Paper
        style={{
          padding: '1em',
          marginBlock: '1em',
        }}
        elevation={3}
      >
        {!!title && <Typography variant="h1">{title}</Typography>}
        {children}
      </Paper>
    </Box>
  );
};
