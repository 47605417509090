import React from 'react';
import { Container, Stack, Typography } from '@mui/material';

import RequestAccessForm from './RequestAccessForm';
import { PageWrapper } from '../components/PageWrapper';

const NotAuthorized = ({ user }) => {
  return (
    <PageWrapper title="Not Authorized">
      <Stack spacing={2}>
        <Typography variant="body1" className="instruction-text">
          You are not authorized to view this page. The following options are available:
        </Typography>

        <Typography variant="body1" align="left" className="instruction-list">
          <br />
          &bull; Click the back button in your browser to return to the previous page. <br />
          &bull; Try refreshing this page with different credentials. <br />
          &bull; Request access below. <br />
        </Typography>
        <RequestAccessForm user={user} />
      </Stack>
    </PageWrapper>
  );
};

export default NotAuthorized;
