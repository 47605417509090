import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getServerUrl } from './utils';

import './App.css';

import { ThemeProvider } from '@mui/material/styles';

import Home from './Home/Home';
import SDSLinks from './Home/SDSLinks';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SpecialConditionsLookup from './components/SpecialConditionsLookup';
import APIKeyForm from './components/APIKeyForm';
import EmergencyAlertForm from './components/EmergencyAlertForm';
import AddSDSLink from './components/AddSDSLink';
import UpdateArrow from './components/UpdateArrow';
import NoMatch from './components/NoMatch';
import SiteAlert from './components/SiteAlert';

import { AnimalOrderForm, ImportForm, InternalTransferForm, ExportForm } from './Animal Forms';
import { Auth, Login, NotAuthenticated, PrivateRoute } from './Auth';
import { LegacySARs, LegacyDARs } from './Sick Animal Reports';
import { InventoryItemsTable, InventorySelectionForm, InventoryOrdersTable, InventoryOrder } from './Inventory';
import { FastRequest, FastRequestsTable, FastRequestTableDisplayView, FastRequestForm } from './Fasting';

import { Dev } from './Dev Tools/Dev';

import Facilities from './Facilities/Facilities';
import { ResourceScheduler, AddResourceForm } from './Resources';

import {
  CustomNotifications,
  CustomNotificationConditionForm,
  UserNotifications,
  NotificationAdmin,
} from './Notifications';

import VCTDashboard from './VCT/VCTDashboard';

import VCTReporting from './Reporting/VCT/VCTReporting';
import SDAReporting from './Reporting/SAR/SDAReporting';

import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from './Theme';

const defaultClient = new ApolloClient({
  link: createHttpLink({
    uri: getServerUrl(),
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => JSON.stringify(o),
  }),
});

function App() {
  const [client, setClient] = useState();
  const [authenticated, setAuthenticated] = useState();
  const [authorized, setAuthorized] = useState();
  const [user, setUser] = useState();
  const [authInitialized, setAuthInitialized] = useState(false);
  // const [siteAlert, setSiteAlert] = useState(!sessionStorage.getItem('siteAlertClosed'));
  const [siteAlert, setSiteAlert] = useState(false);

  const onAuth = ({ authorized, authenticated, client, user }) => {
    setAuthorized(authorized);
    setAuthenticated(authenticated);
    setClient(client);
    setUser(user);
    setAuthInitialized(true);
  };

  const resetState = () => {
    setAuthorized();
    setAuthenticated();
    setClient();
    setUser();
    setAuthInitialized();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      const token = localStorage.getItem('rmaToken');
      if (authInitialized && !token) {
        // Force auth check if token is missing
        // Prevents preserved session after logout in separate tab
        resetState();
      }
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [authInitialized]);

  const logout = () => {
    localStorage.removeItem('rmaToken');
    localStorage.removeItem('shib_idp_session_ss');
    localStorage.removeItem('sessionExpiration');
    resetState();
    window.location.href = 'https://login.wisc.edu/logout';
  };

  const privateRouteProps = {
    authenticated,
    authorized,
    authInitialized,
    user,
    onAuth,
    resetState,
  };
  return (
    <ApolloProvider client={client ? client : defaultClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <div className="App">
              <Navbar user={user} authorizedGroups={['admin', 'superUser', 'husbandrySuperuser']} logout={logout} />
              <SiteAlert open={siteAlert} setOpen={setSiteAlert} />

              <Switch>
                <Route exact path="/Login" component={Login} />
                <Route exact path="/auth" children={<Auth onAuth={onAuth} />} />
                <Route exact path="/auth/:token" children={<Auth onAuth={onAuth} />} />
                <Route path="/authentication-required" component={NotAuthenticated} />
                <Route path="/public-sds" component={SDSLinks} />
                {/* <PrivateRoute
                {...privateRouteProps}
                authorizedGroups={['admin', 'superuser', 'husbandrySuperuser', 'brmsSuperuser']}
                exact
                path="/admin"
                component={Admin}
              /> */}
                <PrivateRoute {...privateRouteProps} exact path="/" component={Home} />
                <PrivateRoute {...privateRouteProps} path="/animal-order" component={AnimalOrderForm} />
                <PrivateRoute {...privateRouteProps} path="/internal-transfer" component={InternalTransferForm} />
                <PrivateRoute {...privateRouteProps} path="/import" component={ImportForm} />
                <PrivateRoute {...privateRouteProps} path="/export" component={ExportForm} />
                <PrivateRoute {...privateRouteProps} path="/request-fast" component={FastRequestForm} />
                <PrivateRoute {...privateRouteProps} path="/fast-requests" exact component={FastRequestsTable} />
                <PrivateRoute {...privateRouteProps} path="/fast-requests/:number" component={FastRequest} />
                <PrivateRoute {...privateRouteProps} exact path="/user-notifications" component={UserNotifications} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/user-notifications/unsubscribe/:protocolId"
                  component={UserNotifications}
                />
                <PrivateRoute {...privateRouteProps} path="/notification-admin" component={NotificationAdmin} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/legacy-sar"
                  authorizedGroups={['husbandrySuperuser', 'admin']}
                  component={LegacySARs}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/legacy-dar"
                  authorizedGroups={['husbandrySuperuser', 'admin']}
                  component={LegacyDARs}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/sar-reporting"
                  authorizedGroups={['admin']}
                  component={SDAReporting}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-items"
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={InventoryItemsTable}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/custom-notifications"
                  authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                  component={CustomNotifications}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/custom-notification-form"
                  authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                  component={CustomNotificationConditionForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/order-inventory"
                  exact
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventorySelectionForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-orders"
                  exact
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventoryOrdersTable}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/inventory-orders/:number"
                  authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                  component={InventoryOrder}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/special-considerations"
                  authorizedGroups={['brmsSuperuser', 'admin', 'brms', 'husbandry']}
                  component={SpecialConditionsLookup}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/create-api-key"
                  authorizedGroups={['admin']}
                  component={APIKeyForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/emergency-alert"
                  authorizedGroups={['admin', 'brmsSuperuser']}
                  component={EmergencyAlertForm}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/fast-request-display"
                  authorizedGroups={['fastRequestReadOnly', 'admin']}
                  component={FastRequestTableDisplayView}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/facilities"
                  exact
                  authorizedGroups={['admin', 'facilitySupervisor', 'brmsSuperuser']}
                  component={Facilities}
                />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard" exact component={VCTDashboard} />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard/:vcrId" exact component={VCTDashboard} />
                <PrivateRoute {...privateRouteProps} path="/vct-dashboard/?form" exact component={VCTDashboard} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/vct-reporting"
                  exact
                  authorizedGroups={['admin']}
                  component={VCTReporting}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/add-sds"
                  exact
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={AddSDSLink}
                />
                <PrivateRoute {...privateRouteProps} path="/sds" component={SDSLinks} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/add-resource"
                  authorizedGroups={['brmsSuperuser', 'admin']}
                  component={AddResourceForm}
                />
                <PrivateRoute {...privateRouteProps} exact path="/resource-scheduler" component={ResourceScheduler} />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/resource-scheduler/:resourceId"
                  component={ResourceScheduler}
                />
                <PrivateRoute
                  {...privateRouteProps}
                  path="/update-arrow"
                  exact
                  authorizedGroups={['admin']}
                  component={UpdateArrow}
                />
                {process.env.NODE_ENV === 'development' && <Route path="/dev" component={Dev} />}
                <Route path="*" component={NoMatch} />
              </Switch>
              <Footer />
            </div>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

console.log(process.env.NODE_ENV);
export default App;
